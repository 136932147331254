import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Club from "../pages/club/Club";
import Member from "../pages/member/Member";
import Work from "../pages/work/Work";
import Allwork from "../pages/allwork/Allwork";
import Login from "../pages/auth/login/Login";
import Register from "../pages/auth/register/Signup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClubMember from "../pages/clubmember/ClubMember";
import CountryAdmin from "../pages/countryadmin/CountryAdmin";

const AppRoute = () => {
return (
  <>
  {console.log('hiii')}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/club" element={<Club />} />
        <Route path="/member" element={<Member />} />
        <Route path="/work" element={<Work />} />
        <Route path="/allwork" element={<Allwork />} />
        <Route path="/clubmember/:ids" element={<ClubMember />} />
        <Route path="/countryadmin" element={<CountryAdmin />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        // closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AppRoute;

import { Bars } from "react-loader-spinner";
import Country from "../../assets/countries.json";
import React, { useEffect, useState } from "react";
import LOGO from "../../assets/images/logogc.png";
import { BiBriefcase } from "react-icons/bi";
import "../../App.css";
import { useTranslation } from "react-i18next";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
  TeamOutlined,
  BarsOutlined,
  DownOutlined,
  DashboardOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Input,
  Layout,
  Menu,
  Modal,
  Form,
  Table,
  Select,
  Dropdown,
  Space,
  Switch,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import Logout from "../../component/logout/Logout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../component/model/Model";
import ReactHtmlParser from "react-html-parser";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { API_URL } from "../../helper/Helper";
const { Header, Sider, Content } = Layout;

// const { Option, OptGroup } = Select;

const Work = () => {
  const { t } = useTranslation();
  const [addLanguageButton, setAddLanguageButton] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [search, setSearch] = useState("");
  const [CreateWorkObject, setCreateWorkObject] = useState([]);
  const [videoLink, setVideoLink] = useState("");
  const [editVideoLink, setEditVideoLink] = useState("");
  const [logOutModal, setLogOutModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(
    localStorage.getItem("token") ? localStorage.getItem("token") : null
  );
  const [isCreateWorkModal, setIsWorkModal] = useState(false);
  const [data, setData] = useState([]);
  const [visibelDeleteModal, setVisibleDaleteModal] = useState(false);
  const [deleteWorkId, setDeleteWorkId] = useState("");
  const [isUpdateWork, setIsUpdateWork] = useState(false);
  const [editWorkId, setEditWorkId] = useState("");
  const [UpdateWorkObject, setUpdateWorkObject] = useState([]);
  const [viewContentModal, setViewContentModal] = useState(false);
  const [viewHtmlContent, setViewHtmlContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [CreateLanguage, setCreateLanguage] = useState([]);

  const navigate = useNavigate();
  const [countryName, setcountryName] = useState(
    JSON.parse(localStorage.getItem("userdata")).data.country
  );
  const [userId, setUserId] = useState(
    JSON.parse(localStorage.getItem("userdata")).data._id
  );
  const [dLang, setDLang] = useState(
    JSON.parse(localStorage.getItem("userdata")).data.deafultLanguage
  );

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    getWorkData();
    setCreateLanguage(
      JSON.parse(localStorage.getItem("userdata")).data?.language?.split(",")
    );
  }, []);

  const youtubeLinkRegex =
    /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})(\?[^\s&]+)?$/;

  const columns = [
    {
      title: t("S.no"),
      dataIndex: "s_no",
      sorter: {
        compare: (a, b) => a.s_no - b.s_no,
        multiple: 5,
      },
    },
    {
      title: t("Work"),
      dataIndex: "wname",
      sorter: {
        compare: (c, d) => (c.cname < d.cname ? 1 : -1),
        multiple: 5,
      },
    },
    {
      title: t("Work Video"),
      dataIndex: "wvideo",
      render: (wvideo) =>
        youtubeLinkRegex.test(wvideo.trim()) ? (
          <a href={wvideo} target="_blank" rel="noopener noreferrer">
            {wvideo}
          </a>
        ) : (
          <span>{wvideo}</span>
        ),
    },
    {
      title: t("Html Content"),
      dataIndex: "whtml",
      // sorter: {
      //   compare: (c, d) => (c.cname < d.cname ? 1 : -1),
      //   multiple: 5,
      // },
    },
    {
      title: t("Operation"),
      fixed: "right",
      width: 100,
      dataIndex: "action",
      render: (index, record) => (
        <Dropdown
          // onMenuClick={(e) => handleUpdate(record, e)}
          overlay={
            <Menu
              // onClick={(e) => handleMenuClick(record, e)}
              items={[
                {
                  key: "1",
                  label: (
                    <Button
                      style={{ border: "none" }}
                      onClick={() => onClickUpdateWork(record)}
                    >
                      {t("Update")}
                    </Button>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Button
                      style={{ border: "none" }}
                      onClick={() => onClickDeleteWork(record)}
                    >
                      {t("Delete")}
                    </Button>
                  ),
                },
              ]}
            />
          }
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <Button style={{ border: "none" }}>
                <BarsOutlined style={{ marginRight: 2 }} />
                <DownOutlined />
              </Button>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const onClickViewContent = (content) => {
    setViewHtmlContent(content);
    setViewContentModal(true);
  };

  //  --------------------------
  const languageAddHandler = () => {
    const arry = [];
    const mainArry = [];
    const updatedWorkObject = [...UpdateWorkObject];
    for (let i = 0; i < UpdateWorkObject.length; i++) {
      arry.push(UpdateWorkObject[i].language);
    }
    for (let i = 0; i < CreateLanguage.length; i++) {
      if (!arry.includes(CreateLanguage[i])) {
        mainArry.push(CreateLanguage[i]);
      }
    }
    for (let i = 0; i < mainArry.length; i++) {
      updatedWorkObject.push({
        htmlContant: "",
        language: mainArry[i],
        name: "",
        workhtml: "",
      });
    }
    setUpdateWorkObject(updatedWorkObject);
    setAddLanguageButton(false);
  };

  // -----------------------------

  //  --------------------------
  const handleInputChange = (event, index, data) => {
    const updatedArray = [...CreateWorkObject];
    if (event.target) {
      const { name, value } = event.target;
      updatedArray[index] = {
        ...updatedArray[index],
        [name]: value,
        language: CreateLanguage[index],
      };
    } else {
      updatedArray[index] = {
        ...updatedArray[index],
        htmlContant: data,
      };
    }
    setCreateWorkObject(updatedArray);
  };

  //  -------------------------

  //  -------------------------
  const handleUpdateInputChange = (event, index, data) => {
    const updatedArray = [...UpdateWorkObject];
    if (event.target) {
      const { name, value } = event.target;
      updatedArray[index] = {
        ...updatedArray[index],
        [name]: value,
      };
    } else {
      updatedArray[index] = {
        ...updatedArray[index],
        htmlContant: data,
      };
    }
    setUpdateWorkObject(updatedArray);
  };

  const cancleLanguage = (data) => {
    setUpdateWorkObject(
      UpdateWorkObject.filter(function (el) {
        return el.language != data.language;
      })
    );
    setAddLanguageButton(true);
  };

  const getWorkData = () => {
    setLoading(true);
    fetch(`${API_URL}/api/work/getCountryWiseWorks/${countryName}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((item) => {
        setLoading(false);
        // setAllDataofCreateWork(item)
        setData(
          item?.data.map((e, i) => {
            const workData = JSON.parse(e.work);
            const defaultContent = workData.find(
              (work) => work?.language === dLang
            );
            const content = defaultContent
              ? defaultContent.htmlContant
              : workData[0].htmlContant;
            return {
              s_no: i + 1,
              id: e._id,
              wname: e.name ? e.name : workData[0].name,
              workData,
              wvideo: e.videoLink ? e.videoLink : t("No video link found"),
              whtml: (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button onClick={() => onClickViewContent(content)}>
                      {t("View Content")}
                    </Button>
                  </div>
                </>
              ),
            };
          })
        );
      });
  };

  const filterData = !search
    ? data
    : data.filter((item) => {
        const name = item.wname;
        if (name) {
          return name.toLocaleLowerCase().indexOf(search.toLowerCase()) !== -1;
        }
        return false;
      });

  const onClickUpdateWork = (record) => {
    // Update the UpdateWorkObject state with the new name value
    setUpdateWorkObject(record.workData);

    // Set other state values
    setIsUpdateWork(true);
    setEditWorkId(record.id);
    setEditVideoLink(youtubeLinkRegex.test(record.wvideo) ? record.wvideo : "");

    // Determine whether to show the add language button
    if (record.workData.length === CreateLanguage.length) {
      setAddLanguageButton(false);
    } else {
      setAddLanguageButton(true);
    }
  };

  const onClickDeleteWork = (record) => {
    setDeleteWorkId(record.id);
    setVisibleDaleteModal(true);
  };

  const onClickLogOutModal = () => {
    setLogOutModal(true);
  };

  const AddshowModal = () => {
    setIsWorkModal(true);
  };

  const searchChangehandler = (events) => {
    setSearch(events.target.value);
  };

  const createClubModalOk = () => {
    setIsWorkModal(false);
  };

  const createClubModalCancel = () => {
    setCreateWorkObject([]);
    setIsWorkModal(false);
  };

  const onClickCancelUpdateWork = () => {
    setUpdateWorkObject([]);
    setIsUpdateWork(false);
  };

  //CREATE WORK
  const onClickSubmitCreateWorkModal = async () => {
    const langauge = CreateLanguage.find((lan) => lan === dLang);
    const langaugeIndex = CreateLanguage.indexOf(langauge);
    const name = CreateWorkObject[langaugeIndex]?.name
      ? CreateWorkObject[langaugeIndex].name
      : "";
    const htmlContant = CreateWorkObject[langaugeIndex]?.htmlContant
      ? CreateWorkObject[langaugeIndex].htmlContant
      : "";

    const showErrorToast = (message) => {
      toast.error(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    if (name.trim().length === 0) {
      showErrorToast(t("Please Input Work Name"));
    } else if (htmlContant.trim().length === 0) {
      showErrorToast(t("Please Input Html Content"));
    } else if (videoLink && !youtubeLinkRegex.test(videoLink.trim())) {
      showErrorToast(t("Please Input a valid YouTube Video link"));
    } else {
      setIsLoading(true);
      const WorkArray = CreateWorkObject.filter(
        (work) => work !== null && work !== undefined
      );
      const request = {
        work: JSON.stringify(WorkArray),
        country: countryName,
        videoLink,
        name,
      };

      try {
        const response = await fetch(`${API_URL}/api/work/CreateWork`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(request),
        });

        const data = await response.json();

        if (response.status === 200) {
          getWorkData();
          setCreateWorkObject([]);
          setVideoLink("");
          setIsWorkModal(false);
          toast.success(data.message, { position: "top-right" });
        } else {
          toast.error(data.message, { position: "top-right" });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  //UPDATE WORK
  const handleUpdateWork = async () => {
    const defaultData = UpdateWorkObject.find(
      (work) => work.language === dLang
    );
    const name = defaultData?.name ? defaultData.name : "";
    const htmlContant = defaultData?.htmlContant ? defaultData.htmlContant : "";

    const showErrorToast = (message) => {
      toast.error(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    if (!name || name.trim().length === 0) {
      showErrorToast(t("Please Input Work Name"));
    } else if (!htmlContant || htmlContant.trim().length === 0) {
      showErrorToast(t("Please Input Html Content"));
    } else if (editVideoLink && !youtubeLinkRegex.test(editVideoLink.trim())) {
      showErrorToast(t("Please Input a valid YouTube Video link"));
    } else {
      setIsLoading(true);
      const WorkArray = UpdateWorkObject.filter(
        (work) => work !== null && work !== undefined
      );
      const request = {
        work: JSON.stringify(WorkArray),
        country: countryName,
        videoLink: editVideoLink,
        name,
      };

      try {
        const response = await fetch(
          `${API_URL}/api/work/EditWork/${editWorkId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(request),
          }
        );

        const data = await response.json();

        if (response.status === 200) {
          setIsUpdateWork(false);
          getWorkData();
          setUpdateWorkObject([]);
          setEditVideoLink("");
          toast.success(data.message, { position: "top-right" });
        } else {
          toast.error(data.message, { position: "top-right" });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // DELETE WORK
  const onClickDeleteButton = async () => {
    try {
      const response = await fetch(
        `${API_URL}/api/work/DeleteWork/${deleteWorkId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "DELETE",
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        setVisibleDaleteModal(false);
        getWorkData();
        toast.success(data.message, { position: "top-right" });
      } else {
        toast.error(data.message, { position: "top-right" });
      }
    } catch (error) {
      toast.error("An error occurred while deleting the work", {
        position: "top-right",
      });
    }
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("Image", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(`${API_URL}/api/work/AddImageCkEditor`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: body,
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${API_URL}/${res.data}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      let data = uploadAdapter(loader);
      return data;
    };
  }

  return (
    <Layout style={{ height: "100vh" }}>
      <Bars
        height="80"
        width="80"
        color="#2e653a"
        ariaLabel="bars-loading"
        wrapperStyle={{
          position: "absolute",
          "z-index": "10000",
          height: "100%",
          width: "100%",
          "padding-top": "25%",
          "padding-left": "50%",
        }}
        wrapperClass=""
        visible={isLoading}
      />
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme="light"
        style={{ position: "sticky", height: "100vh", top: "0" }}
      >
        <div className="logo">
          {/* <p className="logoText">GC</p> */}
          <img src={LOGO} height={30} width={30} className="logoText" />
        </div>
        <Menu
          style={{ padding: "24px 0px" }}
          mode="inline"
          defaultSelectedKeys={["5"]}
          items={[
            {
              key: "1",
              icon: <DashboardOutlined />,
              label: <Link to="/dashboard">{t("Dashboard")}</Link>,
            },
            {
              key: "2",
              icon: <TeamOutlined />,
              label: <Link to="/club">{t("Club")}</Link>,
            },
            {
              key: "3",
              icon: <UserOutlined />,
              label: <Link to="/member">{t("Member")}</Link>,
            },
            {
              key: "5",
              icon: <BiBriefcase style={{ fontSize: "18px" }} />,
              label: <Link to="/work">{t("Work")}</Link>,
            },
            {
              key: "6",
              icon: <BiBriefcase style={{ fontSize: "18px" }} />,
              label: <Link to="/allwork">{t("All Work")}</Link>,
            },
            {
              key: "4",
              icon: <LogoutOutlined />,
              label: (
                <a href="javascript:void(0)" onClick={onClickLogOutModal}>
                  {t("Logout")}
                </a>
              ),
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: "0px 20px",
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Header>
        <Content className="main-content">
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item className="workflow">
              <BiBriefcase style={{ fontSize: "18px" }} />
              &nbsp; {t("Work")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              // minHeight: 360,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>
                <b style={{ fontSize: "15px", marginBottom: "20px" }}>
                  {t("Search")}{" "}
                </b>{" "}
                {t("Work Name:")}
              </span>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {CreateLanguage?.map((data, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginLeft: "15px",
                      }}
                    >
                      <h4 style={{ margin: "0px", fontSize: "15px" }}>
                        {data}
                      </h4>
                      &nbsp;
                      <Switch
                        defaultChecked
                        size="small"
                        onChange={(e) => onChange(e, data)}
                      />
                    </div>
                  );
                })}
              </div> */}
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <Input
                onChange={searchChangehandler}
                placeholder={t("Search Work")}
                value={search}
                style={{ marginRight: "10px" }}
              />
              <Button
                type="primary"
                onClick={AddshowModal}
                style={{ float: "right" }}
              >
                {t("Create Work")}
              </Button>
            </div>
            <Table
              tableLayout="auto"
              scroll="unset"
              simple
              bordered
              loading={loading}
              columns={columns}
              dataSource={filterData}
              pagination={{
                ...filterData.pagination,
                showTotal: (total) => `Total ${total} Items`,
              }}
            ></Table>
          </div>
        </Content>
      </Layout>
      {/* CREATE WORK */}
      <Modal
        title={t("Create Work")}
        visible={isCreateWorkModal}
        onOk={createClubModalOk}
        onCancel={createClubModalCancel}
        footer={null}
      >
        <Form
          labelCol={{ span: 6 }}
          //   wrapperCol={{ span: 18 }}
        >
          {/* <Form.Item label={t("country")}>
            <Select
              value={clubInput}
              showSearch
              style={{
                width: "100%",
              }}
              onChange={CountryInput}
            >
              {country.map((item, index) => {
                return (
                  <>
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Form.Item> */}
          {CreateLanguage?.map((data, i) => {
            return (
              <div key={i} style={{ padding: "5px 0px" }}>
                <h2 style={{ fontSize: "18px", fontWeight: "800" }}>
                  {i + 1} . {data} {data === dLang ? "(Default Language)" : ""}
                </h2>
                <Form.Item label={t("Name")} style={{ width: "100%" }}>
                  <Input
                    value={CreateWorkObject[i]?.name || ""}
                    name="name"
                    // onChange={(e) => setWorkName(e.target.value)}
                    onChange={(event) => handleInputChange(event, i)}
                  />
                </Form.Item>

                <Form.Item
                  label={t("Html Content")}
                  style={{ width: "100%" }}
                ></Form.Item>
                <CKEditor
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={Editor}
                  data={CreateWorkObject[i]?.htmlContant || ""}
                  name="htmlContant"
                  style={{ height: "200px" }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // setCreateWorkObject(data);
                    handleInputChange(event, i, data);

                    // setWorkInput({...workInput,html:data})
                  }}
                  // onChange={(event) => handleInputChange(event, i)}
                />
                <hr style={{ margin: "25px 0px" }} />
              </div>
            );
          })}

          <Form.Item label={t("Video Link")} style={{ width: "100%" }}>
            <Input
              type="text"
              value={videoLink}
              onChange={(e) => setVideoLink(e.target.value)}
              placeholder="Paste video link here"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            // wrapperCol={{ offset: 8, span: 16 }}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0",
              marginTop: "20px",
            }}
          >
            <Button type="default" onClick={createClubModalCancel}>
              {t("Cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "10px",
              }}
              onClick={onClickSubmitCreateWorkModal}
            >
              {t("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* UPDATE WORK */}
      {/* updateModel start */}
      <Modal
        title={t("Update Work")}
        visible={isUpdateWork}
        onOk={() => setIsUpdateWork(false)}
        onCancel={onClickCancelUpdateWork}
        footer={null}
      >
        <Form labelCol={{ span: 6 }}>
          {UpdateWorkObject.map((work, i) => {
            return (
              <div key={i} style={{ padding: "5px 0px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <h2 style={{ fontSize: "18px", fontWeight: "800" }}>
                    {i + 1} . {work["language"]} &nbsp;
                    {dLang === work["language"] && t("( Default Language )")}
                  </h2>
                  {dLang !== work["language"] && (
                    <div>
                      <svg
                        style={{ cursor: "pointer" }}
                        onClick={() => cancleLanguage(work)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-x-lg"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                      </svg>
                    </div>
                  )}
                </div>
                <Form.Item label={t("Name")} style={{ width: "100%" }}>
                  <Input
                    value={work.name}
                    name="name"
                    onChange={(event) => handleUpdateInputChange(event, i)}
                  />
                </Form.Item>

                <Form.Item
                  label={t("Html Content")}
                  style={{ width: "100%" }}
                ></Form.Item>
                <CKEditor
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={Editor}
                  data={work.htmlContant}
                  name="htmlContant"
                  style={{ height: "200px" }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleUpdateInputChange(event, i, data);
                  }}
                />
                <hr style={{ margin: "25px 0px" }} />
              </div>
            );
          })}

          {addLanguageButton && (
            <div
              onClick={languageAddHandler}
              style={{
                border: "1.5px solid gray",
                padding: "5px",
                borderRadius: "5px",
                paddingLeft: "15px",
                marginBottom: "20px",
                width: "35%",
                cursor: "pointer",
              }}
            >
              <PlusOutlined /> &nbsp; Add Language
            </div>
          )}

          <Form.Item label={t("Video Link")} style={{ width: "100%" }}>
            <Input
              type="text"
              value={editVideoLink}
              onChange={(e) => setEditVideoLink(e.target.value)}
              placeholder="Paste video link here"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            // wrapperCol={{ offset: 8, span: 16 }}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0",
              marginTop: "20px",
            }}
          >
            <Button type="default" onClick={onClickCancelUpdateWork}>
              {t("Cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "10px",
              }}
              onClick={handleUpdateWork}
            >
              {t("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* updateModel end */}
      {/* View Html Content in Modal */}
      <Modal
        title={t("View Content")}
        visible={viewContentModal}
        onCancel={() => setViewContentModal(false)}
        onOk={() => setViewContentModal(false)}
        footer={null}
      >
        <Form>
          {ReactHtmlParser(viewHtmlContent)}
          <Form.Item
            // wrapperCol={{ offset: 8, span: 16 }}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0",
              marginTop: "20px",
            }}
          >
            <Button type="default" onClick={() => setViewContentModal(false)}>
              {t("Cancel")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* <ToastContainer
        position="top-right"
        autoClose={500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Logout
        visibleLogOutModal={logOutModal}
        onClickLogOutModelCancel={() => setLogOutModal(false)}
      />
      <DeleteModal
        title={t("Work")}
        modalCancel={() => setVisibleDaleteModal(false)}
        modalOk={() => setVisibleDaleteModal(false)}
        onClickDeleteBtn={onClickDeleteButton}
        text={t("Are you sure you want to delete Work?")}
        visibleModal={visibelDeleteModal}
        buttontext={t("Delete")}
      />
    </Layout>
  );
};

export default Work;

import React from "react";
import { Button, Form } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useTranslation } from "react-i18next";


const DeleteModal = ({
  visibleModal,
  modalOk,
  modalCancel,
  onClickDeleteBtn,
  text,
  title,
  buttontext,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={title}
      visible={visibleModal}
      onOk={modalOk}
      onCancel={modalCancel}
      footer={null}
    >
      <Form>
        <h4>{text}</h4>
        <Form.Item
          // wrapperCol={{ offset: 8, span: 16 }}
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "0",
            marginTop: "20px",
          }}
        >
          <Button type="default" onClick={modalCancel}>
          {t("Cancel")}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              marginLeft: "10px",
            }}
            onClick={onClickDeleteBtn}
          >
            {buttontext}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DeleteModal;

import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { useNavigate } from "react-router-dom";
import { Button, Form, Select } from "antd";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { API_URL } from "../../helper/Helper";
import { toast } from "react-toastify";
const { Option } = Select;

const LanguageModel = ({
  visibleLanguageModel,
  onClickLanguageModelCancel,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(["en", "fi", "sv"]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [defaultLan, setDefaultLan] = useState();
  const [loading, setLoading] = useState(false);
  const [defaultLanguage, setDefaultLanguage] = useState([]);

  const [token, setToken] = useState(
    localStorage.getItem("token") ? localStorage.getItem("token") : null
  );
  const UserId = JSON.parse(localStorage.getItem("userdata")).data?._id;

  useEffect(() => {
    setDefaultLanguage(
      JSON.parse(localStorage.getItem("userdata")).data?.language?.split(",")
    );
  }, []);

  const onClickSubmitBtn = () => {
    if (!selectedLanguage)
      return toast.error("Please select app language", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    if (!defaultLan)
      return toast.error("Please select default work language", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    localStorage.setItem("language", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    onClickLanguageModelCancel();
    getDefaultLanguage();
  };
  const onChangeLanguage = (e) => {
    setSelectedLanguage(e);
  };
  const onChangeDefaultLanguage = (e) => {
    setDefaultLan(e);
  };

  const getDefaultLanguage = async () => {
    setLoading(true);
    await fetch(
      `${API_URL}/api/countryadmin/SetDefaultlanguage/${UserId}/${defaultLan}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((e) => {
        var userdata = JSON.parse(localStorage.getItem("userdata"));
        userdata.data["deafultLanguage"] = defaultLan;
        localStorage.setItem("userdata", JSON.stringify(userdata));
        if (e.status === 200) {
          toast.success(e.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      });
  };

  return (
    <Modal
      title={t("Language Model")}
      visible={visibleLanguageModel}
      // onOk={onClickLanguageModelCancel}
      // onCancel={onClickLanguageModelCancel}
      closable={false}
      footer={null}
      width={750}
    >
      <Form labelCol={{ span: 6 }}>
        <Form.Item label={t("App Language")}>
          <Select
            value={selectedLanguage}
            style={{
              width: "100%",
            }}
            onChange={onChangeLanguage}
          >
            {language.map((item, index) => {
              return (
                <>
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                </>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("Default Work Language")}>
          <Select
            value={defaultLan}
            style={{
              width: "100%",
            }}
            onChange={onChangeDefaultLanguage}
          >
            {defaultLanguage?.map((item, index) => {
              return (
                <>
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                </>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          // wrapperCol={{ offset: 8, span: 16 }}
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "0",
            marginTop: "20px",
          }}
        >
          {/* <Button type="default" onClick={onClickLanguageModelCancel}>
            {t("Cancel")}
          </Button> */}
          <Button
            type="primary"
            htmlType="submit"
            style={{
              marginLeft: "10px",
            }}
            onClick={onClickSubmitBtn}
          >
            {t("Submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LanguageModel;

import {
  generateVideoThumbnails,
  importFileandPreview,
} from "@rajesh896/video-thumbnails-generator";
import { Bars } from "react-loader-spinner";

import React, { useEffect, useRef, useState } from "react";
import LOGO from "../../assets/images/logogc.png";
import { BiBriefcase } from "react-icons/bi";
import "../../App.css";
import { useTranslation } from "react-i18next";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
  TeamOutlined,
  BarsOutlined,
  DownOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Input,
  Layout,
  Menu,
  Modal,
  Form,
  Table,
  Dropdown,
  Space,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import Logout from "../../component/logout/Logout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../component/model/Model";
import ReactHtmlParser from "react-html-parser";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { API_URL } from "../../helper/Helper";
const { Header, Sider, Content } = Layout;

// const { Option, OptGroup } = Select;

const Allwork = () => {
  const { t } = useTranslation();

  const [collapsed, setCollapsed] = useState(false);
  const [search, setSearch] = useState("");
  const [workname, setWorkName] = useState("");
  const [workVideo, setWorkVideo] = useState(null);
  const [workhtml, setWorkHtml] = useState("");
  const [logOutModal, setLogOutModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(
    localStorage.getItem("token") ? localStorage.getItem("token") : null
  );
  const [isCreateWorkModal, setIsWorkModal] = useState(false);
  const [data, setData] = useState([]);
  const [visibelDeleteModal, setVisibleDaleteModal] = useState(false);
  const [copyAllWorkId, setCopyAllWorkId] = useState("");
  const [isUpdateWork, setIsUpdateWork] = useState(false);
  const [editWorkId, setEditWorkId] = useState("");
  const [editWorkName, setEditWorkName] = useState("");
  const [editWorkVideo, setEditWorkVideo] = useState("");
  const [editWorkHtml, setEditWorkHtml] = useState("");
  const [editVideo, setEditVideo] = useState(null);
  const [updateVideoPreview, setUpdateVideoPreview] = useState("");
  const [viewContentModal, setViewContentModal] = useState(false);
  const [viewHtmlContent, setViewHtmlContent] = useState("");
  const updateVideoRef = useRef();
  const videoRef = useRef();
  const [videoPreview, setVidePreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dLang, setDLang] = useState(
    JSON.parse(localStorage.getItem("userdata")).data.deafultLanguage
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    getWorkData();
  }, []);

  useEffect(() => {
    if (!workVideo) {
      setVidePreview(undefined);
      return;
    }
    const object = window.URL.createObjectURL(workVideo);
    setVidePreview(object);
    return () => window.URL.revokeObjectURL(object);
  }, [workVideo]);

  useEffect(() => {
    if (!editVideo) {
      setUpdateVideoPreview(undefined);
      return;
    }
    const object = window.URL.createObjectURL(editVideo);
    setUpdateVideoPreview(object);
    return () => window.URL.revokeObjectURL(object);
  }, [editVideo]);

  const youtubeLinkRegex =
    /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;

  const columns = [
    {
      title: t("S.no"),
      dataIndex: "s_no",
      sorter: {
        compare: (a, b) => a.s_no - b.s_no,
        multiple: 5,
      },
    },
    {
      title: t("Work"),
      dataIndex: "wname",
      sorter: {
        compare: (c, d) => (c.cname < d.cname ? 1 : -1),
        multiple: 5,
      },
    },
    {
      title: t("All Work Video"),
      dataIndex: "wvideo",
      render: (wvideo) =>
        youtubeLinkRegex.test(wvideo.trim()) ? (
          <a href={wvideo} target="_blank" rel="noopener noreferrer">
            {wvideo}
          </a>
        ) : (
          <span>{wvideo}</span>
        ),
    },
    {
      title: t("Html Content"),
      dataIndex: "whtml",
      // sorter: {
      //   compare: (c, d) => (c.cname < d.cname ? 1 : -1),
      //   multiple: 5,
      // },
    },
    {
      title: t("Operation"),
      fixed: "right",
      width: 100,
      dataIndex: "action",
      render: (index, record) => (
        <Dropdown
          // onMenuClick={(e) => handleUpdate(record, e)}
          overlay={
            <Menu
              // onClick={(e) => handleMenuClick(record, e)}
              items={[
                {
                  key: "1",
                  label: (
                    <Button
                      style={{ border: "none" }}
                      onClick={() => onClickCopyButton(record)}
                    >
                      {t("Copy")}
                    </Button>
                  ),
                },
                //   {
                //     key: "2",
                //     label: (
                //       <Button
                //         style={{ border: "none" }}
                //         onClick={() => onClickDeleteWork(record)}
                //       >
                //         {t('Delete')}
                //       </Button>
                //     ),
                //   },
              ]}
            />
          }
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <Button style={{ border: "none" }}>
                <BarsOutlined style={{ marginRight: 2 }} />
                <DownOutlined />
              </Button>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const onClickViewContent = (content) => {
    setViewHtmlContent(content);
    setViewContentModal(true);
  };

  const getWorkData = async () => {
    setLoading(true);
    await fetch(`${API_URL}/api/work/getAllAdminWorks`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((item) => {
        setLoading(false);
        setData(
          item?.data.map((e, i) => {
            const workData = JSON.parse(e.work);
            const defaultContent = workData.find(
              (work) => work?.language === dLang
            );
            const content = defaultContent
              ? defaultContent.htmlContant
              : workData[0].htmlContant;
            return {
              s_no: i + 1,
              id: e._id,
              wname: e.name ? e.name : workData[0].name,
              workData,
              wvideo: e.videoLink ? e.videoLink : t("No video link found"),
              whtml: (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button onClick={() => onClickViewContent(content)}>
                      {t("View Content")}
                    </Button>
                  </div>
                </>
              ),
            };
          })
        );
      });
  };

  // const filterData = data.filter((item) => {
  //   return item.wname.toLocaleLowerCase().indexOf(search.toLowerCase()) !== -1;
  // });
  const filterData = !search
    ? data
    : data.filter((item) => {
        const name = item.wname;
        if (name) {
          return name.toLocaleLowerCase().indexOf(search.toLowerCase()) !== -1;
        }
        return false;
      });
  // const onClickCopyButton = (record) => {
  // //   setIsUpdateWork(true);
  // //   setEditWorkId(record.id);
  // //   setEditWorkName(record.wname);
  // //   setEditWorkVideo(record.video);
  // //   setEditWorkHtml(record.html);
  //   setVisibleDaleteModal(true);
  // };

  const onClickCopyButton = (record) => {
    setCopyAllWorkId(record.id);
    setVisibleDaleteModal(true);
  };

  const onClickLogOutModal = () => {
    setLogOutModal(true);
  };

  const AddshowModal = () => {
    setIsWorkModal(true);
  };

  const searchChangehandler = (events) => {
    setSearch(events.target.value);
  };

  const createClubModalOk = () => {
    setIsWorkModal(false);
  };

  const createClubModalCancel = () => {
    setWorkName("");
    setWorkHtml("");
    setWorkVideo("");
    videoRef.current.value = "";
    setVidePreview(null);
    setIsWorkModal(false);
  };

  const onChangeVideoUpload = (e) => {
    setWorkVideo(e.target.files[0]);
  };

  const onClickCancelUpdateWork = () => {
    setEditWorkName("");
    setEditWorkHtml("");
    updateVideoRef.current.value = "";
    setUpdateVideoPreview("");
    setIsUpdateWork(false);
  };

  const handleUpdateWork = async () => {
    if (editWorkName.trim().length === 0) {
      toast.error(t("Please Input Work Name"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (editWorkHtml.trim().length == 0) {
      toast.error(t("Please Input Html Content"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIsLoading(true);

      var formdata = new FormData();
      formdata.append("name", editWorkName);
      formdata.append("video", editVideo);
      formdata.append("htmlContant", editWorkHtml);

      if (editVideo) {
        await generateVideoThumbnails(editVideo, 0)
          .then((thumbnailArray) => {
            //Usage example:
            var thumbnail = dataURLtoFile(thumbnailArray[0], "thumbnail.jpg");
            formdata.append("videoThumbnail", thumbnail);
          })
          .catch((err) => {
            console.error(err);
          });
      }

      fetch(`${API_URL}/api/work/EditWork/${editWorkId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "PUT",
        body: formdata,
      })
        .then((res) => {
          return res.json();
        })
        .then((e) => {
          if (e.status === 200) {
            setIsUpdateWork(false);
            getWorkData();
            toast.success(e.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // if(window.location.reload(true)){
            setEditWorkName("");
            setEditWorkHtml("");
            updateVideoRef.current.value = "";
            setUpdateVideoPreview("");
            // }
          } else {
            toast.error(e.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  };

  const onChangeUpdateVideo = (e) => {
    setEditVideo(e.target.files[0]);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  //CREATE WORK API
  const onClickSubmitCreateWorkModal = async () => {
    if (workname.trim().length === 0) {
      toast.error(t("Please Input Work Name"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (workhtml == "") {
      toast.error(t("Please Input Html Content"), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIsLoading(true);

      var formdata = new FormData();
      formdata.append("name", workname);
      formdata.append("video", workVideo);
      formdata.append("htmlContant", workhtml);

      if (workVideo) {
        await generateVideoThumbnails(workVideo, 0)
          .then((thumbnailArray) => {
            //Usage example:
            var thumbnail = dataURLtoFile(thumbnailArray[0], "thumbnail.jpg");
            formdata.append("videoThumbnail", thumbnail);
          })
          .catch((err) => {
            console.error(err);
          });
      }

      fetch(`${API_URL}/api/work/CreateWork`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: formdata,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status === 200) {
            setIsWorkModal(false);
            getWorkData();

            toast.success(data.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setWorkName("");
            setWorkHtml("");
            setWorkVideo("");
            videoRef.current.value = "";
            setVidePreview("");
          } else {
            toast.error(data.message, {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  };

  const onClickCopyButtonAPi = () => {
    fetch(`${API_URL}/api/countryadmin/CopyWork/${copyAllWorkId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((e) => {
        if (e.status === 200) {
          setVisibleDaleteModal(false);
          getWorkData();
          toast.success(e.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/work");
        } else {
          setVisibleDaleteModal(false);
          toast.error(e.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("Image", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(`${API_URL}/api/work/AddImageCkEditor`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: body,
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${API_URL}/${res.data}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      let data = uploadAdapter(loader);
      return data;
    };
  }

  return (
    <Layout style={{ height: "100vh" }}>
      <Bars
        height="80"
        width="80"
        color="#2e653a"
        ariaLabel="bars-loading"
        wrapperStyle={{
          position: "absolute",
          "z-index": "10000",
          height: "100%",
          width: "100%",
          "padding-top": "25%",
          "padding-left": "50%",
        }}
        wrapperClass=""
        visible={isLoading}
      />
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme="light"
        style={{ position: "sticky", height: "100vh", top: "0" }}
      >
        <div className="logo">
          {/* <p className="logoText">GC</p> */}
          <img src={LOGO} height={30} width={30} className="logoText" />
        </div>
        <Menu
          style={{ padding: "24px 0px" }}
          mode="inline"
          defaultSelectedKeys={["6"]}
          items={[
            {
              key: "1",
              icon: <DashboardOutlined />,
              label: <Link to="/dashboard">{t("Dashboard")}</Link>,
            },

            {
              key: "2",
              icon: <TeamOutlined />,
              label: <Link to="/club">{t("Club")}</Link>,
            },
            {
              key: "3",
              icon: <UserOutlined />,
              label: <Link to="/member">{t("Member")}</Link>,
            },
            {
              key: "5",
              icon: <BiBriefcase style={{ fontSize: "18px" }} />,
              label: <Link to="/work">{t("Work")}</Link>,
            },
            {
              key: "6",
              icon: <BiBriefcase style={{ fontSize: "18px" }} />,
              label: <Link to="/allwork">{t("All Work")}</Link>,
            },
            {
              key: "4",
              icon: <LogoutOutlined />,
              label: (
                <a href="javascript:void(0)" onClick={onClickLogOutModal}>
                  {t("Logout")}
                </a>
              ),
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: "0px 20px",
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Header>
        <Content className="main-content">
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item className="workflow">
              <BiBriefcase style={{ fontSize: "18px" }} />
              &nbsp; {t("All Work")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              // minHeight: 360,
            }}
          >
            <span>
              <b style={{ fontSize: "15px", marginBottom: "20px" }}>
                {t("Search")}{" "}
              </b>{" "}
              {t("Work Name:")}
            </span>
            <div
              style={{
                marginBottom: "20px",
                marginTop: "10px",
              }}
            ></div>

            <Table
              tableLayout="auto"
              scroll="unset"
              simple
              bordered
              loading={loading}
              columns={columns}
              dataSource={filterData}
              pagination={{
                ...filterData.pagination,
                showTotal: (total) => `Total ${total} Items`,
              }}
            ></Table>
          </div>
        </Content>
      </Layout>
      {/* CREATE WORK */}
      <Modal
        title={t("Create Work")}
        visible={isCreateWorkModal}
        onOk={createClubModalOk}
        onCancel={createClubModalCancel}
        footer={null}
      >
        <Form
          labelCol={{ span: 6 }}
          //   wrapperCol={{ span: 18 }}
        >
          <Form.Item label={t("Name")} style={{ width: "100%" }}>
            <Input
              value={workname}
              onChange={(e) => setWorkName(e.target.value)}
            />
          </Form.Item>

          <Form.Item label={t("Upload Video")} style={{ width: "100%" }}>
            <div>
              <input
                type="file"
                ref={videoRef}
                onChange={onChangeVideoUpload}
                id="workvideo"
                style={{ display: "none" }}
              />
              <div>
                <button className="btn">
                  <label htmlFor="workvideo" style={{ cursor: "pointer" }}>
                    {"Choose File"}
                  </label>
                </button>
                <label style={{ marginLeft: 5 }}>
                  {workVideo ? workVideo.name : "No file chosen"}
                </label>
              </div>
              {videoPreview && (
                <video
                  width={"100%"}
                  height={100}
                  controls
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  key={videoPreview}
                >
                  <source src={videoPreview} type="video/mp4" />
                </video>
              )}
            </div>
          </Form.Item>

          <Form.Item
            label={t("Html Content")}
            style={{ width: "100%" }}
          ></Form.Item>
          <CKEditor
            config={{
              extraPlugins: [uploadPlugin],
            }}
            editor={Editor}
            data={workhtml}
            style={{ height: "200px" }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setWorkHtml(data);
              // setWorkInput({...workInput,html:data})
            }}
          />
          <Form.Item
            // wrapperCol={{ offset: 8, span: 16 }}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0",
              marginTop: "20px",
            }}
          >
            <Button type="default" onClick={createClubModalCancel}>
              {t("Cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "10px",
              }}
              onClick={onClickSubmitCreateWorkModal}
            >
              {t("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* updateModel start */}
      <Modal
        title={t("Update Work")}
        visible={isUpdateWork}
        onOk={() => setIsUpdateWork(false)}
        onCancel={onClickCancelUpdateWork}
        footer={null}
      >
        <Form labelCol={{ span: 6 }}>
          <Form.Item label={t("Name")} style={{ width: "100%" }}>
            <Input
              value={editWorkName}
              onChange={(e) => setEditWorkName(e.target.value)}
            />
          </Form.Item>

          <Form.Item label={t("Upload Video")} style={{ width: "100%" }}>
            <div>
              <input
                type="file"
                ref={updateVideoRef}
                onChange={onChangeUpdateVideo}
                id="updateworkvideo"
                style={{ display: "none" }}
              />
              <div>
                <button className="btn">
                  <label
                    htmlFor="updateworkvideo"
                    style={{ cursor: "pointer" }}
                  >
                    {"Choose File"}
                  </label>
                </button>
                <label style={{ marginLeft: 5 }}>
                  {editVideo ? editVideo.name : "No file chosen"}
                </label>
              </div>
              {editWorkVideo && !updateVideoPreview && (
                <video
                  width={"100%"}
                  height={100}
                  controls
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  key={updateVideoPreview ? updateVideoPreview : editWorkVideo}
                >
                  <source
                    src={
                      updateVideoPreview
                        ? updateVideoPreview
                        : `${API_URL}/${editWorkVideo}`
                    }
                    type="video/mp4"
                  />
                </video>
              )}

              {updateVideoPreview && !editWorkVideo && (
                <video
                  width={"100%"}
                  height={100}
                  controls
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  key={updateVideoPreview ? updateVideoPreview : editWorkVideo}
                >
                  <source
                    src={
                      updateVideoPreview
                        ? updateVideoPreview
                        : `${API_URL}/${editWorkVideo}`
                    }
                    type="video/mp4"
                  />
                </video>
              )}

              {updateVideoPreview && editWorkVideo && (
                <video
                  width={"100%"}
                  height={100}
                  controls
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  key={updateVideoPreview ? updateVideoPreview : editWorkVideo}
                >
                  <source
                    src={
                      updateVideoPreview
                        ? updateVideoPreview
                        : `${API_URL}/${editWorkVideo}`
                    }
                    type="video/mp4"
                  />
                </video>
              )}
            </div>
          </Form.Item>

          <Form.Item
            label={t("Html Content")}
            style={{ width: "100%" }}
          ></Form.Item>
          <CKEditor
            config={{
              extraPlugins: [uploadPlugin],
            }}
            editor={Editor}
            data={editWorkHtml}
            style={{ height: "200px" }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEditWorkHtml(data);
            }}
          />
          <Form.Item
            // wrapperCol={{ offset: 8, span: 16 }}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0",
              marginTop: "20px",
            }}
          >
            <Button type="default" onClick={onClickCancelUpdateWork}>
              {t("Cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "10px",
              }}
              onClick={handleUpdateWork}
            >
              {t("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* updateModel end */}
      {/* View Html Content in Modal */}
      <Modal
        title={t("View Content")}
        visible={viewContentModal}
        onCancel={() => setViewContentModal(false)}
        onOk={() => setViewContentModal(false)}
        footer={null}
      >
        <Form>
          {ReactHtmlParser(viewHtmlContent)}
          <Form.Item
            // wrapperCol={{ offset: 8, span: 16 }}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "0",
              marginTop: "20px",
            }}
          >
            <Button type="default" onClick={() => setViewContentModal(false)}>
              {t("Cancel")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* <ToastContainer
          position="top-right"
          autoClose={500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
      <Logout
        visibleLogOutModal={logOutModal}
        onClickLogOutModelCancel={() => setLogOutModal(false)}
      />
      <DeleteModal
        title={t("All Work")}
        modalCancel={() => setVisibleDaleteModal(false)}
        modalOk={() => setVisibleDaleteModal(false)}
        onClickDeleteBtn={onClickCopyButtonAPi}
        text={t("Are you sure you want to Copy Work?")}
        visibleModal={visibelDeleteModal}
        buttontext={t("Copy")}
      />
    </Layout>
  );
};

export default Allwork;
